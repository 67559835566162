import React, { useEffect, useRef, useState } from "react";
import "./FlipbookPlayer.css";
import ControlPanel from "./ControlPanel";
import pauseIcon from "../../assets/pause.png";
import playIcon from "../../assets/play.png";
import replayIcon from "../../assets/replay.png";
import loadingPage from "../../assets/loading.png";

const FlipbookPlayer = (props) => {
  const { rate = 500, populateFunc = () => -1 } = props;

  const book = useRef(null);
  const numPages = useRef(1);
  const hasNavigated = useRef(false);
  const pageNum = useRef(-1);
  const canGetNextFrame = useRef(true);
  const panelActiveTimeoutId = useRef(null);
  const isTouchScreen = useRef(null);
  const ref = useRef(null);
  const [pageUrl, setPageUrl] = useState(loadingPage);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPanelActive, setIsPanelActive] = useState(false);
  const previousTimestamp = useRef(null);
  const panelActiveWindowRate = useRef(2000);

  useEffect(() => {
    if (populateFunc(book, numPages) == 0) {
      isTouchScreen.current = "ontouchstart" in window;
      addControlListeners();
      setIsPlaying(true);
      openPanelActiveWindow();
    }
  }, []);

  useEffect(() => {
    if (isPlaying) {
      hasNavigated.current = false;
      window.requestAnimationFrame(frame);
    }
  }, [isPlaying]);

  const frame = (timestamp) => {
    if (previousTimestamp.current === null) {
      previousTimestamp.current = timestamp;
    } else if (pageNum.current + 1 >= numPages.current) {
      canGetNextFrame.current = false;
      setIsPlaying(false);
      openPanelActiveWindow();
    } else if (timestamp - previousTimestamp.current >= rate) {
      previousTimestamp.current = timestamp;
      setPageUrl(book.current[++pageNum.current]);
    }
    if (canGetNextFrame.current) {
      window.requestAnimationFrame(frame);
    }
  };

  const togglePlay = (value = !canGetNextFrame.current) => {
    canGetNextFrame.current = value;
    setIsPlaying(value);
  };

  const openPanelActiveWindow = () => {
    clearTimeout(panelActiveTimeoutId.current);
    if (ref.current == null || ref.current.getPopupCurrent() == null) {
      setIsPanelActive(true);
      panelActiveTimeoutId.current = setTimeout(
        () => setIsPanelActive(false),
        panelActiveWindowRate.current
      );
    }
  };

  const addControlListeners = () => {
    window.addEventListener("mousemove", openPanelActiveWindow);
    window.addEventListener("keydown", onKeyDownHandler);
  };

  const navigateToPage = (num) => {
    openPanelActiveWindow();
    pageNum.current = Math.round(num);
    pageNum.current = Math.min(
      numPages.current - 1,
      Math.max(0, pageNum.current)
    );
    hasNavigated.current = true;
    setPageUrl(book.current[pageNum.current]);
  };

  const getProgressPercent = () => {
    return (
      book.current &&
      Math.round(((pageNum.current + 1) / numPages.current) * 100)
    );
  };

  const getIsFinished = () => {
    return book.current && pageNum.current + 1 >= numPages.current;
  };

  const getPlayPauseIcon = () => {
    if (getIsFinished()) {
      return replayIcon;
    }
    if (isPlaying) {
      return pauseIcon;
    }
    if (!isPlaying) {
      return playIcon;
    }
  };

  const getNavigationDuration = () => {
    return hasNavigated.current ? 0 : rate / 1000;
  };

  const keySkipFrames = (skipFrameCount) => {
    navigateToPage(pageNum.current + skipFrameCount);
    togglePlay(false);
  };

  const mouseSkipFrames = (skipFrameCount) => {
    const continueMouseSkipping = () => {
      navigateToPage(pageNum.current + skipFrameCount);
      togglePlay(false);
      timeoutId = setTimeout(continueMouseSkipping, rate / 5);
    };

    const stopMouseSkipping = () => {
      clearTimeout(timeoutId);
    };

    let timeoutId = null;
    window.addEventListener("mouseup", stopMouseSkipping, { once: true });
    continueMouseSkipping();
  };

  const touchSkipFrames = (e, skipFrameCount) => {
    const continueTouchSkipping = () => {
      navigateToPage(pageNum.current + skipFrameCount);
      togglePlay(false);
      timeoutId = setTimeout(continueTouchSkipping, rate / 5);
    };

    const stopTouchSkipping = () => {
      clearTimeout(timeoutId);
    };

    let timeoutId = null;
    e.target.addEventListener("touchend", stopTouchSkipping, { once: true });
    continueTouchSkipping();
  };

  const onMouseNavigateHandler = (e) => {
    const continueMouseNavigating = (e) => {
      if (ref.current) {
        let dims = ref.current.getNavigateCurrent().getBoundingClientRect();
        let progressPercent = (e.screenX - dims.x) / dims.width;
        navigateToPage(numPages.current * progressPercent);
      }
    };

    const stopMouseNavigating = () => {
      window.removeEventListener("mousemove", continueMouseNavigating);
      hasNavigated.current = false;
      togglePlay(prevIsPlaying);
    };

    let prevIsPlaying = isPlaying;
    continueMouseNavigating(e);
    togglePlay(false);
    window.addEventListener("mousemove", continueMouseNavigating);
    window.addEventListener("mouseup", stopMouseNavigating, { once: true });
  };

  const onTouchNavigateHandler = (e) => {
    const continueTouchNavigating = (e) => {
      if (ref.current) {
        let dims = ref.current.getNavigateCurrent().getBoundingClientRect();
        let progressPercent =
          (e.targetTouches[0].screenX - dims.x) / dims.width;
        navigateToPage(numPages.current * progressPercent);
      }
    };

    const stopTouchNavigating = () => {
      window.removeEventListener("touchmove", continueTouchNavigating);
      hasNavigated.current = false;
      togglePlay(prevIsPlaying);
    };

    let prevIsPlaying = isPlaying;
    continueTouchNavigating(e);
    togglePlay(false);
    window.addEventListener("touchmove", continueTouchNavigating);
    window.addEventListener("touchend", stopTouchNavigating, { once: true });
  };

  const onPlayPauseHandler = () => {
    if (getIsFinished()) {
      openPanelActiveWindow();
      pageNum.current = -1;
      togglePlay(true);
    } else {
      if (!canGetNextFrame.current) {
        openPanelActiveWindow();
      }
      togglePlay();
    }
  };

  const onKeyDownHandler = (e) => {
    switch (e.code) {
      case "Space":
        if (
          ref.current &&
          document.activeElement == ref.current.getPlayPauseCurrent()
        ) {
          break;
        }
        onPlayPauseHandler();
        break;
      case "KeyA":
      case "ArrowLeft":
        keySkipFrames(-1);
        break;
      case "KeyD":
      case "ArrowRight":
        keySkipFrames(1);
        break;
      default:
    }
  };

  const onMouseRewindHandler = (e) => {
    mouseSkipFrames(-1);
  };

  const onMouseForwardHandler = (e) => {
    mouseSkipFrames(1);
  };

  const onTouchRewindHandler = (e) => {
    touchSkipFrames(e, -1);
  };

  const onTouchForwardHandler = (e) => {
    touchSkipFrames(e, 1);
  };

  return (
    <div className="player-container">
      <img
        src={pageUrl}
        onClick={onPlayPauseHandler}
        alt="Flipbook player"
        className="player"
      />
      {isPanelActive && (
        <ControlPanel
          ref={ref}
          playPauseIcon={getPlayPauseIcon()}
          onPlayPause={onPlayPauseHandler}
          onMouseForward={onMouseForwardHandler}
          onTouchForward={onTouchForwardHandler}
          onMouseRewind={onMouseRewindHandler}
          onTouchRewind={onTouchRewindHandler}
          onMouseNavigate={onMouseNavigateHandler}
          onTouchNavigate={onTouchNavigateHandler}
          progressPercent={getProgressPercent()}
          transitionDuration={getNavigationDuration()}
          hasNavigated={hasNavigated.current}
          isTouchScreen={isTouchScreen.current}
        />
      )}
    </div>
  );
};

export default FlipbookPlayer;
