import React, { useEffect, useRef } from "react";
import "./App.css";
import FlipbookPlayer from "./flipbookPlayer/FlipbookPlayer";

export function App() {
  const appRef = useRef(null);

  useEffect(() => {
    updateSize();
    addEventListener("resize", updateSize);
  }, []);

  const updateSize = () => {
    appRef.current.style.width = `${window.innerWidth}px`;
    appRef.current.style.height = `${window.innerHeight}px`;
  };

  const populatePokemonSprites = (book, numPages) => {
    let limit = 100;
    let offset = 200;
    let rejected = false;
    fetch(`https://pokeapi.co/api/v2/pokemon?limit=${limit}&offset=${offset}`)
      .then((result) => result.json())
      .then((pokemonUrls) => {
        numPages.current = Object.keys(pokemonUrls["results"]).length;
        book.current = new Array(numPages.current);
        for (let i = 0; i < numPages.current; i++) {
          fetch(pokemonUrls["results"][i]["url"])
            .then((result) => result.json())
            .then(
              (pokemon) =>
                (book.current[i] = pokemon["sprites"]["front_default"])
            );
        }
        return;
      })
      .catch(() => (rejected = true));
    return !rejected ? 0 : -1;
  };

  return (
    <div>
      <main className="app" ref={appRef} onDrag={(e) => e.preventDefault()}>
        <FlipbookPlayer rate={500} populateFunc={populatePokemonSprites} />
      </main>
    </div>
  );
}
