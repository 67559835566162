import React, { useRef, useImperativeHandle, useState, useEffect } from "react";
import "./ControlPanel.css";
import forwardIcon from "../../assets/forward.png";
import rewindIcon from "../../assets/rewind.png";
import informationClosedIcon from "../../assets/information-closed.png";
import informationOpenIcon from "../../assets/information-open.png";

const ControlPanel = React.forwardRef((props, ref) => {
  const {
    playPauseIcon,
    onPlayPause,
    onMouseForward,
    onTouchForward,
    onMouseRewind,
    onTouchRewind,
    onMouseNavigate,
    onTouchNavigate,
    progressPercent,
    transitionDuration,
    hasNavigated,
    isTouchScreen,
  } = props;

  const navigateRef = useRef(null);
  const playPauseRef = useRef(null);
  const popupRef = useRef(null);
  const getNavigateCurrent = () => navigateRef.current;
  const getPlayPauseCurrent = () => playPauseRef.current;
  const getPopupCurrent = () => popupRef.current;
  useImperativeHandle(
    ref,
    () => ({ getNavigateCurrent, getPlayPauseCurrent, getPopupCurrent }),
    []
  );

  const [isInformationOpen, setIsInformationOpen] = useState(false);

  const toggleInformation = () => {
    setIsInformationOpen(!isInformationOpen);
  };

  return (
    <div className="panel">
      <div
        ref={navigateRef}
        className="navigation-track"
        onMouseDown={!isTouchScreen ? onMouseNavigate : null}
        onTouchStart={isTouchScreen ? onTouchNavigate : null}
        style={{ height: hasNavigated ? "100%" : "20%" }}
      >
        <div
          className="progress"
          style={{
            width: `${progressPercent}%`,
            height: hasNavigated ? "120%" : "100%",
            transition: `width ${transitionDuration}s linear`,
          }}
        >
          <div className="thumb" />
        </div>
      </div>
      <div className="buttons">
        <button
          className="button"
          onMouseDown={!isTouchScreen ? onMouseRewind : null}
          onTouchStart={isTouchScreen ? onTouchRewind : null}
          style={{ backgroundImage: `url(${rewindIcon})` }}
        />
        <button
          ref={playPauseRef}
          className="button"
          onClick={onPlayPause}
          style={{ backgroundImage: `url(${playPauseIcon})` }}
        />
        <button
          className="button"
          onMouseDown={!isTouchScreen ? onMouseForward : null}
          onTouchStart={isTouchScreen ? onTouchForward : null}
          style={{ backgroundImage: `url(${forwardIcon})` }}
        />
      </div>
      <div className="info">
        {isInformationOpen && (
          <div
            ref={popupRef}
            className="popup"
            onMouseLeave={() => setIsInformationOpen(false)}
          >
            <a
              href="https://gitlab.com/dandruffLord/test-website"
              target="_blank"
            >
              Developed using pure React and CSS
            </a>
            <br />
            <a href="https://www.linkedin.com/in/david-benny/" target="_blank">
              Hire me for a Full-Stack or Front-End Position!
            </a>
            <br />
            Sprites fetched from{" "}
            <a href="https://pokeapi.co" target="_blank">
              pokeapi.co
            </a>
            <br />
            <div>
              Icons made by{" "}
              <a href="https://www.freepik.com" title="Freepik">
                Freepik
              </a>{" "}
              from{" "}
              <a href="https://www.flaticon.com/" title="Flaticon">
                www.flaticon.com
              </a>
            </div>
          </div>
        )}
        <button
          className="button button--info"
          onMouseEnter={!isTouchScreen ? toggleInformation : null}
          onTouchStart={isTouchScreen ? toggleInformation : null}
          style={{
            backgroundImage: `url(${
              isInformationOpen ? informationOpenIcon : informationClosedIcon
            })`,
          }}
        />
      </div>
    </div>
  );
});

export default ControlPanel;
